import React from "react";
import section_img from '../../image/section-img.png';

const Service = () => {
  return (
    <section class="services section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <img src={section_img} alt="#" />
              <p>
                TMC is a leading medical, laboratory, and dental equipment
                supply company established in 1994. Based in Iraq and serving
                the Middle East, we are the Technical Medical Corner Scientific
                Bureau for Pharmaceutical, Medical, Labs, Dental equipment,
                supplies, and TMC.With over 25 years of experience, we have
                become a trusted name in the industry. We aim to provide
                high-quality equipment and supplies to healthcare
                pro-fessionals, laboratories, pharmaceutical companies, and
                dental prac-tices. We offer a comprehensive range of products
                designed to meet the diverse needs of our customers. At TMC, we
                are committed to delivering exceptional service and staying at
                the forefront of medical technology advancements.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <div class="single-service">
              <i class="icofont icofont-hospital"></i>
              <h4>
                <a href="service-details.html">Hospital & Lab Preparation</a>
              </h4>
              <p>
                We supply a wide range of equipment, instruments, Chemi-cals,
                and consumables.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="single-service">
              <i class="icofont icofont-ui-settings"></i>
              <h4>
                <a href="service-details.html">Installation Service</a>
              </h4>
              <p>
                Undertake Installation service for various medical equipment
                used in a Hospital environment.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="single-service">
              <i class="icofont icofont-ui-check"></i>
              <h4>
                <a href="service-details.html">Service after sale</a>
              </h4>
              <p>
                Customers require preventive check services to ascertain whether
                the equipment is performing according to the performance
                stand-ards.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="single-service">
              <i class="icofont icofont-architecture-alt"></i>
              <h4>
                <a href="service-details.html">Technical Maintenance</a>
              </h4>
              <p>
                Preventive Maintenance programs are offered for various med-ical
                equipment per the manufacturer's recommendation and industry
                standards.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="single-service">
              <i class="icofont icofont-flag-alt-2"></i>
              <h4>
                <a href="service-details.html">Our Steratgy</a>
              </h4>
              <p>
                From A to Z Package Deal / Turnkey Projects. We give you the
                power and Complete Solutions to print your lab and hospital
                images. We serve and help you whatever your basis and budget
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="single-service">
              <i class="icofont icofont-speed-meter"></i>
              <h4>
                <a href="service-details.html">Features</a>
              </h4>
              <p>
                On-site repair services 24x7 coverage Depot repair services o
                Preventative maintenance programs Quality assurance programs
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;