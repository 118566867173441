import React from "react";

const SmallCard = (props) => {
  return (
    <div className="col-12 col-md-4 mt-5">
      <div className={`position-relative small_card ${props.bg}`}>
        <div className="hex_container">
          <div className="hex">
            <div className="hex_background">
              <svg
                className="w-6 h-6 text-secondary text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="70"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d={props.svg_d}
                />
              </svg>
            </div>
          </div>
        </div>
        <h3>{props.content}</h3>
      </div>
    </div>
  );
};

export default SmallCard;
