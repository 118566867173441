import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import axios from "axios";

const containerStyle = {
  width: "100%",
  height: "60vh",
};

const locations = [
  "Baghdad, Iraq",
  "Erbil, Iraq",
  "Mosul, Iraq",
  "Duhok, Iraq",
  "Salah Aldin, Iraq",
  "Kirkuk, Iraq",
  "Karbala, Iraq",
  "Basrah, Iraq",
];


// const locations = [
//   {
//     city: "Baghdad, Iraq",
//     lat: 33.32846,
//     lng: 44.36132,
//   },{
//     city: "Erbil, Iraq",
//     lat: 36.20063,
//     lng: 44.00952,
//   }, {
//     city: "Mosul, Iraq",
//     lat: 36.343021,
//     lng: 43.145061,
//   }, {
//     city: "Duhok, Iraq",
//     lat: 36.86631,
//     lng: 42.98734,
//   }, {
//     city: "Salah Aldin, Iraq",
//     lat: 34.72764,
//     lng: 43.69614
//   }, {
//     city: "Kirkuk, Iraq",
//     lat: 35.46983,
//     lng: 44.38389,
//   }, {
//     city: "Karbala, Iraq",
//     lat: 32.60448,
//     lng: 44.02029
//   }, {
//     city: "Basrah, Iraq",
//     lat: 30.53422,
//     lng: 47.76294,
//   }
// ];

const GMap = () => {
  const [markers, setMarkers] = useState([]);
  const [error, setError] = useState(null);
  // const { isLoaded, loadError } = useJsApiLoader({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  // });

  useEffect(() => {
    const fetchGeocode = async () => {
      try {
        const promises = locations.map(async (location) => {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json`,
            {
              params: {
                address: location,
                key: process.env.REACT_APP_GOOGLE_MAP_KEY,
              },
            }
          );

          if (response.data.status === "OK") {
            const { lat, lng } = response.data.results[0].geometry.location;
            return {
              lat,
              lng,
              city: location,
            };
          } else {
            throw new Error("Geocoding API error: " + response.data.status);
          }
        });

        const locationsData = await Promise.all(promises);
        setMarkers(locationsData);
      } catch (error) {
        console.error("Error fetching geocode data: ", error);
        setError(error.message);
      }
    };

    fetchGeocode();
  }, []);

  const blueMarkerIcon = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
  const options = {
    streetViewControl: false, // Disable Pegman (Street View) control
    mapTypeControl: false, // Disable map type control (map style icon)
  };

  return (
    <div>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}>
        {/* {isLoaded ? ( */}
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={markers.length > 0 ? markers[0] : { lat: 0, lng: 0 }}
            zoom={6}
            options={options}
          >
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                // label={location.city}
                icon={blueMarkerIcon}
              />
            ))}
          </GoogleMap>
        {/* ) : (
          <div>Loading Google Maps...</div>
        )} */}
      </LoadScript>
    </div>
  );
};

export default GMap;
