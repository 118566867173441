import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./layout.module.css";
import logo from "../../image/logo.png";

const Footer = () => {
  const isAuthed = useSelector((state) => state.user.isAuthed);
  return (
    <>
      <div className="row mt-5">
        <div className="col-12 col-sm-6 col-md-4 p-3">
          <img src={logo} alt="logo" className={styles.footer_logo} />
          <h3>Technical Medical Corner</h3>
        </div>

        <div className="col-12 col-sm-6 col-md-4 p-3">
          <h3>Main Office / IMC-Iraq</h3>
          <div className="mt-1">
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                />
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"
                />
              </svg>
            </span>
            <span>Baghdad-Iraq</span>
            <br />
            <span>Bab Almutham St. Near the Ministry of Health</span>
          </div>
          <div className="mt-1">
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"
                />
              </svg>
            </span>
            <span>+964 60 766993</span>
            <br />
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 15h12M6 6h12m-6 12h.01M7 21h10a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1Z"
                />
              </svg>
            </span>
            <span>+ 964 7708386000</span>
            <br />
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 15h12M6 6h12m-6 12h.01M7 21h10a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1Z"
                />
              </svg>
            </span>
            <span>+ 964 7701755733</span>
          </div>
          <div className="mt-2">
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
                />
              </svg>
            </span>
            <span>tmc_iraq@yahoo.com</span>
            <br />
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
                />
              </svg>
            </span>
            <span className="text-center">info@tmc-iraq.com</span>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-4 p-3">
          <h3>Erbil branch Office / Erbil-Iraq</h3>
          <div className="mt-1">
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                />
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"
                />
              </svg>
            </span>
            <span>Erbil-Iraq</span>
            <br />
            <span>40 meters near the pharma lab</span>
          </div>
          <div className="mt-1">
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 15h12M6 6h12m-6 12h.01M7 21h10a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1Z"
                />
              </svg>
            </span>
            <span>+ 964 7708386000</span>
            <br />
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 15h12M6 6h12m-6 12h.01M7 21h10a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1Z"
                />
              </svg>
            </span>
            <span>+ 964 7701755733</span>
          </div>
          <div className="mt-2">
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
                />
              </svg>
            </span>
            <span>tmc_iraq@yahoo.com</span>
            <br />
            <span>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
                />
              </svg>
            </span>
            <span className="text-center">info@tmc-iraq.com</span>
          </div>
        </div>
      </div>

      <hr />

      <div className="row">
        <p className="col-10">
          The website content is legally protected @2024 TMC-IRAQ (Cooperated by{" "}
          <span>
            <Link target="_blank" to="http://wmktechn.net">
              wmktechn.net
            </Link>
          </span>
          )
        </p>
        <div className="col-2 d-flex justify-content-end flex-fill">
          {isAuthed ? (
            <Link target="_blank" to={process.env.REACT_APP_FACEBOOK_URL}>
              <i
                className={`${styles.logined_social_link} bi bi-facebook mx-1 h3`}
              ></i>
            </Link>
          ) : (
            <Link to="/login">
              <i className="bi bi-facebook me-2 text-secondary h3"></i>
            </Link>
          )}
          {isAuthed ? (
            <Link target="_blank" to={process.env.REACT_APP_INSTAGRAM_URL}>
              <i
                className={`${styles.logined_social_link} bi bi-instagram mx-1 h3`}
              ></i>
            </Link>
          ) : (
            <Link to="/login">
              <i className="bi bi-instagram me-2 text-secondary h3"></i>
            </Link>
          )}
          {isAuthed ? (
            <Link target="_blank" to={process.env.REACT_APP_LINKEDIN_URL}>
              <i
                className={`${styles.logined_social_link} bi bi-linkedin mx-1 h3`}
              ></i>
            </Link>
          ) : (
            <Link to="/login">
              <i className="bi bi-linkedin me-2 text-secondary h3"></i>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default Footer;
