import React from "react";
import CompInfoCard from "./comp_info_card";
import ContactForm from "./contact_form";
import styles from "./page1.module.css";
import img_pd from "../../image/pd.png";
import man1 from '../../image/man (1).jpg';
import man2 from "../../image/man (2).jpg";
import man3 from "../../image/man (3).jpg";
import man4 from "../../image/man (4).jpg";
import man5 from "../../image/man (5).png";
import StuffInfoCard from "./stuff_info_card";

const Page1 = () => {
  return (
    <div>
      <div className="row text-center">
        <h1>Please contact us</h1>
        <hr />
      </div>

      <div className="row">
        <CompInfoCard
          title="Main Office / TMC-IRAQ, Baghdad-Iraq"
          location="Bab Almutham St. Near the Ministry of Health  "
          tel1="+964 60 766993"
          tel2="+964 60 817002"
          mob1="+ 964 7708386000"
          mob2="+ 964 7701755733"
          email1="tmc_iraq@yahoo.com"
          email2="info@tmc-iraq.com"
          web="www.tmc-iraq.com"
          contact="Eng.KUTAIBA ALASSAF"
          style1={styles.small_card_half}
          style2={styles.bg1}
          hex_svg_d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"
        />

        <CompInfoCard
          title="Erbil branch Office / TMC-IRAQ, Erbil-Iraq"
          location="40 meters near the pharma lab"
          tel1=""
          tel2=""
          mob1="+ 964 7708386000"
          mob2="+ 964 7701755733"
          email1="tmc_iraq@yahoo.com"
          email2="info@tmc-iraq.com"
          web="www.tmc-iraq.com"
          contact="Dc.AWS ALSASSF"
          style1={styles.small_card_half}
          style2={styles.bg2}
          hex_svg_d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
        />
      </div>

      <div className="row">
        <div className="col-12 col-md-6 mt-5">
          <img src={img_pd} className={styles.pd_img} alt="pd" />
        </div>
        <div className="col-12 col-md-6 mt-5">
          <ContactForm />
        </div>
      </div>

      <div className="row">
        <StuffInfoCard
          name="Dr.AWS ALASSAF"
          prof="Salary director"
          lang="languages: Polish, English, Russian"
          tel="+48 602 119 003"
          email="mariusz@biofaktor.pl"
          bg={styles.bg1}
          photo={man1}
          photo_style={styles.circular_portrait}
        />
        <StuffInfoCard
          name="Ana Krynicka"
          prof="Area airport manager"
          lang="languages: Polish, English, Russian"
          tel="+48 795 128 654"
          email="sales@biofaktor.pl"
          bg={styles.bg2}
          photo={man2}
          photo_style={styles.circular_portrait}
        />
        <StuffInfoCard
          name="Alaa Osman"
          prof="Area export manager"
          lang="languages: Polish, English, Russian"
          tel="+48 604 066 140"
          email="trades@biofaktor.pl"
          bg={styles.bg3}
          photo={man3}
          photo_style={styles.circular_portrait}
        />
        <StuffInfoCard
          name="Lukasz Leszczynski"
          prof="Area export manager"
          lang="languages: Polish, English, Russian"
          tel="+48 602 119 003"
          email="lukaszleszczynski@biofaktor.pl"
          bg={styles.bg4}
          photo={man4}
          photo_style={styles.circular_portrait}
        />
        <StuffInfoCard
          name="Krzysztof Trabski"
          prof="Area export manager"
          lang="languages: Polish, English, Russian"
          tel="+48 602 119 003"
          email="krzysztof@biofaktor.pl"
          bg={styles.bg5}
          photo={man5}
          photo_style={styles.circular_portrait}
        />
      </div>
    </div>
  );
};

export default Page1;
