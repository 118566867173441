import React from "react";
import section_img from "../../image/section-img.png";

const Ideology = () => {
  return (
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <h2>Our Ideology</h2>
            <img src={section_img} alt="#" />
          </div>
        </div>
      </div>
      <div className="fun-facts">
        <div className="overlay">
          <div className="row container fun-fact-container">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="fun-fact d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="ms-3">
                    <span className="p-0 m-0">Energy</span>
                    <p>People are the primary soure of our Energy</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div className=" d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="ms-3">
                    <span className="p-0 m-0">Value</span>
                    <p>Adding Value is our primary goal</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div className="fun-fact d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="ms-3">
                    <span className="p-0 m-0">Our Efforts</span>
                    <p>All our efforts are directed at achieving this Goal</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div className="fun-fact d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="ms-3">
                    <span className="p-0 m-0">Our Success</span>
                    <p>Our success will be shared amongst the people</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ideology;
