import React from "react";

const StuffInfoCard = (props) => {
  return (
    <div className="col-sm-12 col-md-6 pt-3">
      <div
        className={`d-flex align-items-center p-3 p-1 pt-3 rounded-3 ${props.bg}`}
      >
        <div className={`col-md-3 col-lg-4 ${props.photo_style}`}>
          <img className="img-fluid" src={props.photo} alt="photo1" />
        </div>
        <div className="col-md-9 col-lg-8 ps-3">
          <h3>{props.name}</h3>
          <p>{props.prof}</p>
          <p>{props.lang}</p>
          <p>
            <svg
              className="w-6 h-6 pe-2 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"
              />
            </svg>
            {props.tel}
          </p>
          <p>
            <svg
              className="w-6 h-6 pe-2 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
              />
            </svg>
            {props.email}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StuffInfoCard;
