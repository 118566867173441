import React from "react";
import { Outlet } from 'react-router-dom';
import Topbar from "./topbar";
import Footer from "./footer";

const Layout = () => (
  <div className="container">
    <Topbar />
    <Outlet />
    <Footer />
  </div>
);

export default Layout;
