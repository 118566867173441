import React from "react";
import SideMenu from "./side_menu";
import styles from "./page2.module.css";
import ProdCard from "./prod_card";
import prod1 from "../../image/x1.png";
import prod2 from "../../image/x12.png";
import prod3 from "../../image/virLotus.jpg";

const Page2 = () => {
  return (
    <>
      <div className="row bg-white rounded-1">
        <div className="col-sm-12 col-md-6 d-flex justify-content-center">
          <div className="input-group">
            <span className="input-group-text">
              <i className="bi bi-search"></i>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
            />
            <select className="form-select">
              <option value="all">All</option>
              <option value="name">Name</option>
              <option value="category">Category</option>
            </select>
          </div>
        </div>

        <div className="col-sm-12 col-md-4 d-flex align-items-center">
          <label className="text-nowrap">Short by:</label>
          <select className="form-select" id="short_select">
            <option value="all">All</option>
            <option value="name">Name</option>
            <option value="category">Category</option>
          </select>
        </div>

        <div className="col-sm-12 col-md-2 d-flex align-items-center">
          <label>View:</label>
          <i className="bi bi-grid-3x3-gap ms-2"></i>
          <i className="bi bi-list-task ms-2"></i>
        </div>
      </div>

      <div className="row mt-2">
        <SideMenu />

        <div className="col-sm-12 col-md-9 p-0">
          <div className="row">
            <ProdCard
              img_src={prod1}
              img_style={styles.prod_img}
              rating="5"
              prod_name="Prod1"
            />
            <ProdCard
              img_src={prod2}
              img_style={styles.prod_img}
              rating="5"
              prod_name="Prod2"
            />
            <ProdCard
              img_src={prod3}
              img_style={styles.prod_img}
              rating="5"
              prod_name="Prod3"
            />
            <ProdCard
              img_src={prod1}
              img_style={styles.prod_img}
              rating="5"
              prod_name="Prod1"
            />
            <ProdCard
              img_src={prod2}
              img_style={styles.prod_img}
              rating="5"
              prod_name="Prod2"
            />
            <ProdCard
              img_src={prod3}
              img_style={styles.prod_img}
              rating="5"
              prod_name="Prod3"
            />
            <ProdCard
              img_src={prod1}
              img_style={styles.prod_img}
              rating="5"
              prod_name="Prod1"
            />
            <ProdCard
              img_src={prod2}
              img_style={styles.prod_img}
              rating="5"
              prod_name="Prod2"
            />
            <ProdCard
              img_src={prod3}
              img_style={styles.prod_img}
              rating="5"
              prod_name="Prod3"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Page2;
