import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './components/layout';
import Page1 from './components/page1';
import Page2 from './components/page2';
import Page3 from './components/page3';
import Home from './components/home';
import Login from './components/user/login';
import Register from './components/user/register';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path='/page1' element={<Page1 />} />
          <Route path='/page2' element={<Page2 />} />
          <Route path='/page3' element={<Page3 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
