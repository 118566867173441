import React from "react";

const SupCard = (props) => {
  return (
    <div className="col-12 col-md-3 col-sm-6">
      <div className="sup_card pb-0 d-flex mt-3 align-items-center">
        <div className="rounded-circle">
          <svg
            className="w-6 h-6 ms-3 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth="2"
              d={props.svg_d}
            />
          </svg>
        </div>
        <div className="mx-3">
          <h5>{props.sup_title}</h5>
          <p>{props.sup_content}</p>
        </div>
      </div>
    </div>
  );
};

export default SupCard;
