import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASE_URL;
axios.defaults.withCredentials = true;

const userSlice = createSlice({
  name: "user",
  initialState: {
    id: "",
    name: "",
    email: "",
    isAuthed: false,
    status: "idle",
    error: null,
  },

  reducers: {
    authed: (state, action) => {      
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.isAuthed = true;
      state.status = 'idle';
      state.error = null;
    },
    logouted: (state) => {
      state.id = "";
      state.name = "";
      state.email = "";
      state.isAuthed = false;
      state.status = "idle";
      state.error = null;  
    }
  },

  extraReducers(builder) {
    builder
      .addMatcher(
        (action) => [register.pending, login.pending].includes(action.type),
        (state, action) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        (action) => [register.fulfilled, login.fulfilled].includes(action.type),
        (state, action) => {
          state.status = "successed";
        }
      )
      .addMatcher(
        (action) => [register.rejected, login.rejected].includes(action.type),
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        }
      );
  },
});

export const login = createAsyncThunk("user/login", async (loginData) => {
  const response = await axios.post("/api/login", loginData);
  return response.data;
});

export const logout = createAsyncThunk("user/logout", async () => {
  const response = await axios.get("/api/logout");
  return response.data;
});

export const register = createAsyncThunk(
  "user/register",
  async (registerData) => {
    const response = await axios.post(
      "/api/register",
      registerData
    );
    return response.data;
  }
);

export const { authed, logouted } = userSlice.actions;
export default userSlice.reducer;
