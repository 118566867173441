import React from "react";
import SmallCard from './small_card';
import SupCard from "./sup_card";
import styles from "./home.module.css";
import nurse from '../../image/nurse.png';
import pd from '../../image/pd.png';
import x1 from '../../image/x1e.jpg';
import x12 from '../../image/x12e.jpg';

const Home = () => {
  return (
    <div>
      <div className={`row border-0 text-white mt-5 ${styles.bg_grad1}`}>
        <div className="col-sm-12 col-md-6 d-flex align-items-center">
          <div className="m-5">
            <h1>A wide Range Of Products</h1>
            <p>Each customer will find a product suited to their needs</p>
            <button
              type="button"
              className={`btn rounded-pill ${styles.btn_bg_danger}`}
            >
              Find out
            </button>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 text-center">
          <img
            className={`${styles.img_nurse} img-fluid`}
            src={nurse}
            alt="nurse"
          />
        </div>
      </div>

      <div className="row mt-3">
        <h1 className="text-center mt-3">Meet Doctor</h1>
        <hr />

        <SmallCard
          bg={styles.bg1}
          content="The most modern production line"
          svg_d="M8.737 8.737a21.49 21.49 0 0 1 3.308-2.724m0 0c3.063-2.026 5.99-2.641 7.331-1.3 1.827 1.828.026 6.591-4.023 10.64-4.049 4.049-8.812 5.85-10.64 4.023-1.33-1.33-.736-4.218 1.249-7.253m6.083-6.11c-3.063-2.026-5.99-2.641-7.331-1.3-1.827 1.828-.026 6.591 4.023 10.64m3.308-9.34a21.497 21.497 0 0 1 3.308 2.724m2.775 3.386c1.985 3.035 2.579 5.923 1.248 7.253-1.336 1.337-4.245.732-7.295-1.275M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
        />

        <SmallCard
          bg={styles.bg2}
          content="21 years of experience in the industry"
          svg_d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
        />

        <SmallCard
          bg={styles.bg3}
          content="The highest quality of production"
          svg_d="M3 21h18M4 18h16M6 10v8m4-8v8m4-8v8m4-8v8M4 9.5v-.955a1 1 0 0 1 .458-.84l7-4.52a1 1 0 0 1 1.084 0l7 4.52a1 1 0 0 1 .458.84V9.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5Z"
        />
      </div>

      <div className={`row border-0 text-white mt-5 ${styles.bg_grad2}`}>
        <div className="col-sm-12 col-md-6 text-center">
          <img className={`${styles.img_pd} img-fluid`} src={pd} alt="nurse" />
        </div>
        <div className="col-sm-12 col-md-6 d-flex align-items-center">
          <div className="m-5">
            <h1>Check out our online product catalog!</h1>
            <p>
              All Otolaryngology products are available in the catalog. As part
              of the online catalog, filters have been created that facilitate
              the search for products by their specifications and parameters.
            </p>
            <button
              type="button"
              className={`btn rounded-pill ${styles.btn_bg_danger}`}
            >
              See The Catalog
            </button>
          </div>
        </div>
      </div>

      <div className={`row border-0 text-white mt-5 ${styles.bg_grad3}`}>
        <div className="col-sm-12 col-md-6 d-flex align-items-center">
          <div className="m-5">
            <h1>Subscribe Now For</h1>
            <h1>Get Every Day Tips</h1>
            <p>A wonderful ...</p>
            <div className={styles.input_group_sub}>
              <input
                type="text"
                placeholder="Enter your..."
                className="rounded-pill border-0 form-control"
              />
              <button
                type="button"
                className={`btn rounded-pill ${styles.btn_bg_danger} ${styles.btn_sub}`}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div className={`col-sm-12 col-md-6  ${styles.sub_img}`}></div>
      </div>

      <div className="row mt-5">
        <SupCard
          sup_title="Worldwide Delivery"
          sup_content="From A to Z package deal / turnkey projects"
          svg_d="M4.37 7.657c2.063.528 2.396 2.806 3.202 3.87 1.07 1.413 2.075 1.228 3.192 2.644 1.805 2.289 1.312 5.705 1.312 6.705M20 15h-1a4 4 0 0 0-4 4v1M8.587 3.992c0 .822.112 1.886 1.515 2.58 1.402.693 2.918.351 2.918 2.334 0 .276 0 2.008 1.972 2.008 2.026.031 2.026-1.678 2.026-2.008 0-.65.527-.9 1.177-.9H20M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />

        <SupCard
          sup_title="Safe Payment"
          sup_content="We serve and help you whatever your basis and budget"
          svg_d="M3 10h18M6 14h2m3 0h5M3 7v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1Z"
        />

        <SupCard
          sup_title="Shop department"
          sup_content="We deal with honesty with all stakeholders"
          svg_d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312"
        />

        <SupCard
          sup_title="24/7 Support"
          sup_content="Customer satisfaction is our #1 Goal"
          svg_d="M12 2a7 7 0 0 0-7 7 3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h1a1 1 0 0 0 1-1V9a5 5 0 1 1 10 0v7.083A2.919 2.919 0 0 1 14.083 19H14a2 2 0 0 0-2-2h-1a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h1a2 2 0 0 0 1.732-1h.351a4.917 4.917 0 0 0 4.83-4H19a3 3 0 0 0 3-3v-2a3 3 0 0 0-3-3 7 7 0 0 0-7-7Zm1.45 3.275a4 4 0 0 0-4.352.976 1 1 0 0 0 1.452 1.376 2.001 2.001 0 0 1 2.836-.067 1 1 0 1 0 1.386-1.442 4 4 0 0 0-1.321-.843Z"
        />
      </div>

      {/* Carousel */}
      <div className="row mt-5">
        <div
          id="carouselExampleAutoplaying"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={x1} className="d-block w-100" alt="x1" />
            </div>
            <div className="carousel-item">
              <img src={x12} className="d-block w-100" alt="x12" />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
