import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authed, register } from "./userSlice";

import tmc_intro from "../../image/tmc_intro.png";

const Register = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const registerState = useSelector(state => state.user.status);
  // const error = useSelector(state => state.user.error);

  const [registerData, setRegisterData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [registerStatus, setRegisterStatus] = useState("idle");

  const onChange = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };

  const onClickRegister = async () => {
    if (
      registerStatus === "idle" &&
      registerData.password === registerData.password_confirmation
    ) {
      try {
        setRegisterStatus("pending");
        const response = await dispatch(register(registerData)).unwrap();
        if (response.message === "register success") {
          dispatch(authed(response.user));
        }

        setRegisterData({
          name: "",
          email: "",
          password: "",
          password_confirmation: "",
        });
        navigate("/");
      } catch (err) {
        console.error("Failed to register: ", err);
      } finally {
        setRegisterStatus("idle");
      }
    } else {
      alert("No match password!");
    }
  };

  return (
    <section className="vh-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 text-black">
            <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">
              <form style={{ width: "23rem" }}>
                <h3
                  className="fw-normal mb-3 pb-3"
                  style={{ letterSpacing: "1px" }}
                >
                  Register
                </h3>

                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="text"
                    id="form2Example17"
                    className="form-control form-control-lg"
                    name="name"
                    value={registerData.name}
                    onChange={onChange}
                  />
                  <label className="form-label" for="form2Example18">
                    Name
                  </label>
                </div>

                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="email"
                    id="form2Example18"
                    className="form-control form-control-lg"
                    name="email"
                    value={registerData.email}
                    onChange={onChange}
                  />
                  <label className="form-label" for="form2Example18">
                    Email address
                  </label>
                </div>

                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="password"
                    id="form2Example28"
                    className="form-control form-control-lg"
                    name="password"
                    value={registerData.password}
                    onChange={onChange}
                  />
                  <label className="form-label" for="form2Example28">
                    Password
                  </label>
                </div>

                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="password"
                    id="form2Example29"
                    className="form-control form-control-lg"
                    name="password_confirmation"
                    value={registerData.password_confirmation}
                    onChange={onChange}
                  />
                  <label className="form-label" for="form2Example29">
                    Confirm Password
                  </label>
                </div>

                <div className="pt-1 mb-4">
                  <button
                    data-mdb-button-init
                    data-mdb-ripple-init
                    className="btn btn-info btn-lg btn-block rounded-pill"
                    type="button"
                    onClick={onClickRegister}
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-6 px-0 d-none d-sm-block">
            <img
              src={tmc_intro}
              className="img-fluid"
              style={{ objectFit: "cover", objectPosition: "left" }}
              alt="logo"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
