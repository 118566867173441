import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./layout.module.css";
import logo from "../../image/logo.png";

import { logout, logouted } from "../user/userSlice";

const Topbar = () => {
  const dispatch = useDispatch();
  const isAuthed = useSelector((state) => state.user.isAuthed);
  
  const onClickLogout = async () => {
    const response = await dispatch(logout()).unwrap();
    if (response.message === "logout success") {
      dispatch(logouted());
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="logo" className={styles.topbar_logo} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-between"
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav">
              <li className={`nav-item ${styles.nav_link_green}`}>
                <Link className="nav-link" to="/">
                  Meet Dr
                </Link>
              </li>
              <li className={`nav-item ${styles.nav_link_dodgerblue}`}>
                <Link className="nav-link" to="/page1">
                  Contact
                </Link>
              </li>
              <li className={`nav-item ${styles.nav_link_mediumseagreen}`}>
                <Link className="nav-link" to="/page2">
                  Product
                </Link>
              </li>
              <li className={`nav-item ${styles.nav_link_orange}`}>
                <Link className="nav-link" to="/page3">
                  About Us
                </Link>
              </li>
            </ul>

            <ul className={`navbar-nav pb-0 ${styles.social_nav}`}>
              <li className="nav-item me-2">
                {isAuthed ? (
                  <Link target="_blank" to={process.env.REACT_APP_FACEBOOK_URL}>
                    <i
                      className={`${styles.logined_social_link} bi bi-facebook mx-1 h2`}
                    ></i>
                  </Link>
                ) : (
                  <Link to="/login">
                    <i className="bi bi-facebook mx-1 text-secondary h2"></i>
                  </Link>
                )}
              </li>
              <li className="nav-item me-2">
                {isAuthed ? (
                  <Link
                    target="_blank"
                    to={process.env.REACT_APP_INSTAGRAM_URL}
                  >
                    <i
                      className={`${styles.logined_social_link} bi bi-instagram mx-1 h2`}
                    ></i>
                  </Link>
                ) : (
                  <Link to="/login">
                    <i className="bi bi-instagram mx-1 text-secondary h2"></i>
                  </Link>
                )}
              </li>
              <li className="nav-item me-3">
                {isAuthed ? (
                  <Link target="_blank" to={process.env.REACT_APP_LINKEDIN_URL}>
                    <i
                      className={`${styles.logined_social_link} bi bi-linkedin mx-1 h2`}
                    ></i>
                  </Link>
                ) : (
                  <Link to="/login">
                    <i className="bi bi-linkedin mx-1 text-secondary h2"></i>
                  </Link>
                )}
              </li>
              <li className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i
                    className={`bi bi-person-fill mx-1 h2 ${
                      isAuthed ? "text-primary" : "text-secondary"
                    }`}
                  ></i>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    {isAuthed ? (
                      <button className="dropdown-item" onClick={onClickLogout}>
                        Log out
                      </button>
                    ) : (
                      <Link className="dropdown-item" to="/login">
                        Log In
                      </Link>
                    )}
                  </li>
                  <li>
                    {!isAuthed && (
                      <Link className="dropdown-item" to="/register">
                        Register
                      </Link>
                    )}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Topbar;
