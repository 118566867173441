import React from "react";

const Rating = (props) => {
  return (
    <div>
      {[...Array(5)].map((_, index) => (
        <i
          key={index}
          className={
            index < props.rating
              ? "bi bi-star-fill me-1 text-warning"
              : "bi bi-star-fill me-1 text-black-50"
          }
        />
      ))}
    </div>
  );
};

export default Rating;
