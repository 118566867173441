import React from "react";
import dev1 from "../../image/dev1.png";
import dev2 from "../../image/dev2.png";
import dev3 from "../../image/dev3.png";

const DevSlide = () => {
  return (
    <div className="row">
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        {/* indicators */}
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="0"
            class="active"
          ></button>
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="1"
          ></button>
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="2"
          ></button>
        </div>

        {/* slides */}
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={dev1} className="d-block w-100" alt="x1" />
          </div>
          <div className="carousel-item">
            <img src={dev2} className="d-block w-100" alt="x12" />
          </div>
          <div className="carousel-item">
            <img src={dev3} className="d-block w-100" alt="x12" />
          </div>
        </div>
        {/* test */}
        <h1
          style={{
            position: "absolute",
            top: "10%",
            left: "7%",
            color: "#1a76d1",
          }}
          className="fw-bold"
        >
          We Offer Different Services <br />
          To Improve Your Health
        </h1>

        {/* left and right controls */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>

        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default DevSlide;