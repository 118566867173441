import React from "react";
import Rating from "./rating";

const ProdCard = (props) => {
  return (
    <div className="col-sm-12 col-md-4">
      <div className="card prod_card ms-2">
        <div className="d-flex justify-content-center align-items-center">
          <img
            src={props.img_src}
            className={`card-img-top img-fluid p-4 ${props.img_style}`}
            alt="prod1"
          />
        </div>
        <div className="card-body">
          <h5>{props.prod_name}</h5>
          <Rating rating={props.rating} />
          <div className="d-flex justify-content-between">
            <p className="card-text text-danger">$123.45</p>
            <i className="bi bi-plus-square text-danger"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProdCard;
