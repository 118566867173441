import React from "react";
import Rating from "./rating";
import styles from './page2.module.css';

const SideMenu = (props) => {
  return (
    <div className="col-sm-12 col-md-3 mb-3 rounded-1 bg-white">
      <div className="category mt-3">
        <h5>Category</h5>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#components-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <span>Menu1</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="components-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="/page2">
                  <span>Prod1</span>
                </a>
              </li>
              <li>
                <a href="/page2">
                  <span>Prod2</span>
                </a>
              </li>
              <li>
                <a href="/page2">
                  <span>Prod3</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#forms-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <span>Menu2</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="forms-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="/page2">
                  <span>Prod1</span>
                </a>
              </li>
              <li>
                <a href="/page2">
                  <span>Prod2</span>
                </a>
              </li>
              <li>
                <a href="/page2">
                  <span>Prod3</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#tables-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <span>Menu3</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="tables-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="/page2">
                  <span>Prod1</span>
                </a>
              </li>
              <li>
                <a href="/page2">
                  <span>Prod2</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <hr />

      <div className="price_range">
        <h5>Price Range</h5>
        <div className="row">
          <div className="col-sm-12 col-md-5">
            <input className="form-control" type="text"></input>
          </div>
          <div className="col-2 text-center">
            <span>-</span>
          </div>
          <div className="col-sm-12 col-md-5">
            <input className="form-control" type="text"></input>
          </div>
        </div>
      </div>
      <hr />

      <div className="brands">
        <h5>Brands</h5>
        <div>
          <input className="form-check-input me-2" type="checkbox" value="" />
          <label className="form-check-label">Brand1</label>
        </div>
        <div>
          <input className="form-check-input me-2" type="checkbox" value="" />
          <label className="form-check-label">Brand2</label>
        </div>
        <div>
          <input className="form-check-input me-2" type="checkbox" value="" />
          <label className="form-check-label">Brand3</label>
        </div>
      </div>
      <hr />

      <div className="">
        <div>
          <input className="form-check-input me-2" type="checkbox" value="" />
          <label className="form-check-label">On Sale</label>
        </div>
        <div>
          <input className="form-check-input me-2" type="checkbox" value="" />
          <label className="form-check-label">In Stock</label>
        </div>
        <div>
          <input className="form-check-input me-2" type="checkbox" value="" />
          <label className="form-check-label">Featured</label>
        </div>
      </div>
      <hr />

      <div className="ratings">
        <h5>Ratings</h5>
        <div className="d-flex align-items-center">
          <input className="form-check-input me-2" type="checkbox" value="" />
          <Rating rating={5} />
        </div>

        <div className="d-flex align-items-center">
          <input className="form-check-input me-2" type="checkbox" value="" />
          <Rating rating={4} />
        </div>

        <div className="d-flex align-items-center">
          <input className="form-check-input me-2" type="checkbox" value="" />
          <Rating rating={3} />
        </div>

        <div className="d-flex align-items-center">
          <input className="form-check-input me-2" type="checkbox" value="" />
          <Rating rating={2} />
        </div>

        <div className="d-flex align-items-center">
          <input className="form-check-input me-2" type="checkbox" value="" />
          <Rating rating={1} />
        </div>
      </div>
      <hr />

      <div className="colors">
        <h5>Colors</h5>
        <i className={`bi bi-circle-fill me-1 ${styles.circle_color1}`}></i>
        <i className={`bi bi-circle-fill me-1 ${styles.circle_color2}`}></i>
        <i className={`bi bi-circle-fill me-1 ${styles.circle_color3}`}></i>
        <i className={`bi bi-circle-fill me-1 ${styles.circle_color4}`}></i>
        <i className={`bi bi-circle-fill me-1 ${styles.circle_color5}`}></i>
        <i className={`bi bi-circle-fill me-1 ${styles.circle_color6}`}></i>
      </div>
    </div>
  );
}

export default SideMenu;