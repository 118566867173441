import React from "react";

const ContactForm = (props) => {
  return (
    <div className="card shadow p-5 border-0">
      <form>
        <h1>Contact form</h1>
        <p>Fields marked with an asterssic are required</p>
        <div className="row">
          <div className="col-12 col-md-6">
            <label>First and last name *</label>
            <input
              type="text"
              placeholder="first and last name"
              className="form-control"
            />
          </div>
          <div className="col-12 col-md-6">
            <label>E-mail address *</label>
            <input
              type="text"
              placeholder="E-mail address"
              className="form-control"
            />
          </div>
          <div className="mt-3">
            <label>Message content *</label>
            <textarea placeholder="Content" className="form-control" rows="7" />
          </div>
          <div className="col-12 col-md-12 mt-2">
            <input className="form-check-input me-2" type="checkbox" value="" />
            <label className="form-check-label">Contact to the peronal TMC-Iraq *</label>
          </div>
          <div className="mt-2">
            The administrator of your personal TMC-Iraq. The Administrator can
            be contacted by sending an e-mail to the following e-mail address:
            tmc_iraq@yahoo.com, info@tmc-iraq.com.
            <br />by writing to the following address:
            <br />Bab Almutham St. Near the Ministry of Health.<br />
            BY CALLING THE TELEPHONE NUMBER:
            <br />
            (Phone): +964 60 766993, +964 60 817002,
            <br />
            (Mobile): +964 7708386000, + 964 7701755733. <br />
            Your personal data will
            be processed in order to answer the message sent. More information
            on the processing of personal data can be fmured in the Privarcy
            Policy
          </div>
          <button className="btn btn-primary mt-3">
            Send A Message{" "}
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
