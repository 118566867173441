import React from "react";
import section_img from "../../image/section-img.png";
import Map from './gmap';
import Service from "./service";
import Ideology from "./ideology";
import DevSlide from "./dev_slide";

const Page3 = () => {
  return (
    <div>
      <DevSlide />
      <Service />
      <Ideology />
     
      <div className="row mt-5">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title mt-5">
              <h2>Find Us</h2>
              <img src={section_img} alt="#" />
            </div>
          </div>
        </div>
        <Map />
      </div>
    </div>
  );
};

export default Page3;
